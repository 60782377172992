import React from "react";
import SwaggerUI from "swagger-ui";
import PropTypes from "prop-types";

import "swagger-ui/dist/swagger-ui.css";
import "./index.css";

const InfoContainer = () => {
  return (
    <div>
      <div className="info">
        <hgroup className="main">
          <h2 className="title">Loop API Reference</h2>
        </hgroup>
        <div className="description">
          <div className="markdown">
            <p>
              This API provides developers with the ability to extract key,
              synthesized information from a given conversation. Using this API
              you can pass in a transcript of a video conference made using
              tools such as Zoom, Cisco WebEx, or Microsoft Teams and this API
              will summarize the conversation and identify key next steps.
            </p>
            <p>
              This API is based on a RESTful architecture and are accessed via
              HTTPS using specified URLs. Using our API reference, you can send
              test requests and view responses. In order to use this API you
              must first request an API key which you may do{" "}
              <a href="#operations-Highlights_Extractor-emailTokenUsingPOST">
                here
              </a>
              .
            </p>
            <p>
              With each release, we add additional APIs and enhancements to meet
              the needs of our customers. If you have any questions about this
              API, please{" "}
              <a href="mailto:api-questions@obindohq.com">contact us</a> at any
              time.
            </p>
          </div>
        </div>
        {/* <div className="info__tos">
              <a target="_blank" href="urn:tos" rel="noopener noreferrer" className="link">Terms of service</a>
            </div>
            <div className="info__license">
              <a target="_blank" href="http://www.apache.org/licenses/LICENSE-2.0" rel="noopener noreferrer"
                className="link">Apache 2.0
              </a>
            </div> */}
      </div>
    </div>
  );
};

export default class OperationsLayout extends React.Component {
  static propTypes = {
    errSelectors: PropTypes.object.isRequired,
    errActions: PropTypes.object.isRequired,
    specSelectors: PropTypes.object.isRequired,
    oas3Selectors: PropTypes.object.isRequired,
    oas3Actions: PropTypes.object.isRequired,
    getComponent: PropTypes.func.isRequired,
  };

  render() {
    let { errSelectors, specSelectors, getComponent } = this.props;

    let SvgAssets = getComponent("SvgAssets");
    //let InfoContainer = getComponent("InfoContainer", true)
    let VersionPragmaFilter = getComponent("VersionPragmaFilter");
    let Operations = getComponent("operations", true);
    let Models = getComponent("Models", true);
    let Row = getComponent("Row");
    let Col = getComponent("Col");
    let Errors = getComponent("errors", true);

    const ServersContainer = getComponent("ServersContainer", true);
    const SchemesContainer = getComponent("SchemesContainer", true);
    const AuthorizeBtnContainer = getComponent("AuthorizeBtnContainer", true);
    const FilterContainer = getComponent("FilterContainer", true);
    let isSwagger2 = specSelectors.isSwagger2();
    let isOAS3 = specSelectors.isOAS3();

    const isSpecEmpty = !specSelectors.specStr();

    const loadingStatus = specSelectors.loadingStatus();

    let loadingMessage = null;

    if (loadingStatus === "loading") {
      loadingMessage = (
        <div className="info">
          <div className="loading-container">
            <div className="loading"></div>
          </div>
        </div>
      );
    }

    if (loadingStatus === "failed") {
      loadingMessage = (
        <div className="info">
          <div className="loading-container">
            <h4 className="title">Failed to load API definition.</h4>
            <Errors />
          </div>
        </div>
      );
    }

    if (loadingStatus === "failedConfig") {
      const lastErr = errSelectors.lastError();
      const lastErrMsg = lastErr ? lastErr.get("message") : "";
      loadingMessage = (
        <div className="info failed-config">
          <div className="loading-container">
            <h4 className="title">Failed to load remote configuration.</h4>
            <p>{lastErrMsg}</p>
          </div>
        </div>
      );
    }

    if (!loadingMessage && isSpecEmpty) {
      loadingMessage = <h4>No API definition provided.</h4>;
    }

    if (loadingMessage) {
      return (
        <div className="swagger-ui">
          <div className="loading-container">{loadingMessage}</div>
        </div>
      );
    }

    const servers = specSelectors.servers();
    const schemes = specSelectors.schemes();

    const hasServers = servers && servers.size;
    const hasSchemes = schemes && schemes.size;
    const hasSecurityDefinitions = !!specSelectors.securityDefinitions();

    return (
      <div className="swagger-ui">
        <SvgAssets />
        <VersionPragmaFilter
          isSwagger2={isSwagger2}
          isOAS3={isOAS3}
          alsoShow={<Errors />}
        >
          <Errors />
          <Row className="information-container">
            <Col mobile={12}>
              <InfoContainer />
            </Col>
          </Row>

          {hasServers || hasSchemes || hasSecurityDefinitions ? (
            <div className="scheme-container">
              <Col className="schemes wrapper" mobile={12}>
                {hasServers ? <ServersContainer /> : null}
                {hasSchemes ? <SchemesContainer /> : null}
                {hasSecurityDefinitions ? <AuthorizeBtnContainer /> : null}
              </Col>
            </div>
          ) : null}

          <FilterContainer />

          <Row>
            <Col mobile={12} desktop={12}>
              <Operations />
            </Col>
          </Row>
          <Row>
            <Col mobile={12} desktop={12}>
              <Models />
            </Col>
          </Row>
        </VersionPragmaFilter>
      </div>
    );
  }
}

// Create the plugin that provides our layout component
const OperationsLayoutPlugin = () => {
  return {
    components: {
      OperationsLayout: OperationsLayout,
    },
  };
};

const getUrl = () => {
  const origin = window.location.origin;
  return `${origin.replace("3000", "8080")}/v2/api-docs`;
};

// Provide the plugin to Swagger-UI, and select OperationsLayout
// as the layout for Swagger-UI
SwaggerUI({
  url: getUrl(),
  dom_id: "#root",
  plugins: [OperationsLayoutPlugin],
  layout: "OperationsLayout",
});
